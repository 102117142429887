import React, { useEffect, useState, useRef, useMemo } from 'react'
import styles from './index.module.scss'
import { useFileDragDrop } from 'hooks/files/useFileDragDrop'
import axios from 'axios'
import { isValidFileExtension, isValidFileVersion } from 'utils/files/helper'
import Kicker from '../Kicker'
import CircleProgressBar from '../CircleProgressBar/CircleProgressBar'
import cx from 'classnames'
import { getCognitoUserSession, getIdToken } from 'utils/user/getCognitoUserSession'
import { useRouter } from 'next/router'
import { TheatersFillIcon, TheatersIcon, UploadErrorIcon, UploadFillIcon, UploadSuccessIcon } from '../Icons'
import { MAX_FILE_SIZE_IN_KB } from '~constants'

interface IFileUploadProgressProps {
  uploadControlRef: React.RefObject<HTMLInputElement>
  isUploading: boolean
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>
  uploadContainerRef: React.RefObject<HTMLDivElement>
  validateFile: Function
  dashboardStyles: { readonly [key: string]: string }
}

enum FileUploadState {
  INITIAL = 1,
  WARNING = 2,
  INFO = 3,
  ERROR = 4,
  SUCCESS = 5
}

type FileUploadStateType =
  | 'INVALID_FILE_TYPE'
  | 'INVALID_FILE_NAME'
  | 'VERSION_NUMBER_NOT_FOUND'
  | 'VERSION_NUMBER_ALREADY_EXISTS'
  | 'UPLOAD'
  | 'UPLOAD_ERROR'
  | 'FILE_SIZE_ERROR'
  | 'FILE_NAME_TOO_LONG'

const UploadSelectedFile = ({ dashboardStyles }: any) => {
  const router = useRouter()
  const [isUploading, setIsUploading] = useState(false)

  const fileUploadControlRef = useRef<HTMLInputElement>(null)
  const uploadContainerRef = useRef<HTMLDivElement>(null)
  const [fileUploadState, setFileUploadState] = useState<FileUploadStateType>('UPLOAD')

  const [fileInfo, setFileInfo] = useState({ name: '', size: 0 })
  const [percentComplete, setPercentComplete] = React.useState(1)

  const [isUploaded, setIsUploaded] = useState(false)
  const [userId, setUserId] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      const userSession = await getCognitoUserSession()
      setUserId(userSession?.userId || null)
    })()
  }, [])

  function finishUploading(isServerError = false) {
    setIsUploading(false)
    setPercentComplete(0)
    setIsUploaded(false)

    if (fileUploadControlRef.current) {
      fileUploadControlRef.current.value = ''
    }
    if (!isServerError) {
      setFileInfo({ name: '', size: 0 })
    }
  }

  function uploadFile(file: File) {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('data', JSON.stringify({ userId }))

    let xhr = new XMLHttpRequest()

    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        let percentage = Math.floor((event.loaded * 100) / event.total)
        setPercentComplete(percentage - 1)
      }
    }

    xhr.open('POST', '/api/upload', true)

    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        setPercentComplete(100)
        setIsUploaded(true)
        setTimeout(() => {
          finishUploading()
          router.reload()
        }, 3000)
      } else {
        finishUploading(true)
        setFileUploadState('UPLOAD_ERROR')
      }
    }

    xhr.onerror = function () {
      finishUploading(true)
      setFileUploadState('UPLOAD_ERROR')
      console.error('Network error')
    }

    xhr.send(formData)
  }

  function uploadDraggedFile(file: File) {
    if (file && isUploading) {
      uploadFile(file)
    }
  }

  useFileDragDrop({
    styles,
    container: uploadContainerRef,
    fileValidation: validateFile,
    uploadFile: uploadDraggedFile,
    isUploading,
    setFileInfo,
    fileInfo
  })

  useEffect(() => {
    if (isUploading && fileUploadControlRef && fileUploadControlRef.current) {
      if (fileUploadControlRef.current.files && fileUploadControlRef.current.files.length > 0) {
        uploadFile(fileUploadControlRef.current.files[0])
      }
    }
  }, [isUploading])

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    const { files } = e.target
    const selectedFiles = files as FileList

    if (selectedFiles && selectedFiles.length > 0) {
      const fileName = selectedFiles[0].name
      const fileSize = selectedFiles[0].size

      if (fileSize > 0) {
        const fileSizeInKB = Math.round(fileSize / 1024)
        setFileInfo({ name: fileName, size: fileSizeInKB })
        validateFile(fileName, fileSizeInKB)
      }
    }
  }

  function validateFile(fileName: string, fileSize: number) {
    const fileParts = fileName.split('_')

    if (fileSize > MAX_FILE_SIZE_IN_KB) {
      setFileUploadState('FILE_SIZE_ERROR')
    } else if (fileName.length > 100) {
      setFileUploadState('FILE_NAME_TOO_LONG')
    } else if (!isValidFileExtension(fileName)) {
      setFileUploadState('INVALID_FILE_TYPE')
    } else if (fileParts.length < 3 || fileParts.some(x => x.trim() === '')) {
      setFileUploadState('INVALID_FILE_NAME')
    } else if (fileParts.length > 2) {
      const versionNumber = fileParts[fileParts.length - 1].split('.')[0]

      if (!isValidFileVersion(versionNumber)) {
        setFileUploadState('VERSION_NUMBER_NOT_FOUND')
      } else {
        const idToken = getIdToken()

        axios
          .post<{ result: boolean }>(
            `${process.env.NEXT_PUBLIC_API_ENDPOINT}/file-exists`,
            { videoFileName: fileName },
            { headers: { Authorization: `Bearer ${idToken}` } }
          )
          .then(res => {
            if (res.data && res.data.result) {
              setFileUploadState('VERSION_NUMBER_ALREADY_EXISTS')
            } else {
              setIsUploading(true)
            }
          })
      }
    }
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (fileUploadState !== 'UPLOAD') {
      timeoutId = setTimeout(() => {
        if (fileUploadControlRef.current) {
          fileUploadControlRef.current.value = ''
        }

        setFileUploadState('UPLOAD')
      }, 5000)
    }

    return () => clearTimeout(timeoutId)
  }, [fileUploadState])

  const FileUploadError = ({ uploaded = 'upload error', uploadErrorDesc = '', uploadErrorTitle = '' }) => {
    return (
      <div className={styles['file-upload-error']}>
        <div>
          <div>
            <TheatersIcon styles={styles} />
          </div>
          <div className={styles['file-info']}>
            <Kicker fontSize="16px" fontWeight="600" letterSpacing="none" textTransform="none" text={fileInfo.name} wordBreak="break-all" />
            <Kicker fontSize="14px" fontWeight="350" letterSpacing="none" textTransform="none" text={`${fileInfo.size} KB - ${uploaded}`} />
          </div>
        </div>
        <div>
          <div className={styles['error-info']}>
            <Kicker fontSize="16px" fontWeight="600" letterSpacing="none" textTransform="none" text={uploadErrorTitle} />
            <Kicker fontSize="14px" fontWeight="350" letterSpacing="none" textTransform="none" text={uploadErrorDesc} />
          </div>
          <div className={styles['error-info-icon']} data-testid="upload-error-icon">
            <UploadErrorIcon />
          </div>
        </div>
      </div>
    )
  }

  const FileUploadProgress = () => {
    return (
      <>
        <div className={styles['file-upload-error']}>
          <div>
            <div>
              <TheatersFillIcon />
            </div>
            <div className={styles['file-info']}>
              <Kicker fontSize="16px" fontWeight="600" letterSpacing="none" textTransform="none" text={fileInfo.name} wordBreak="break-all" />
              <Kicker
                opacity="0.7"
                fontSize="14px"
                fontWeight="400"
                letterSpacing="none"
                textTransform="none"
                text={`${fileInfo.size} KB - ${percentComplete}% uploaded`}
              />
            </div>
          </div>
          <div>
            <div className={styles['error-info']}></div>
            <div className={styles['error-info-icon']}>
              {isUploaded || percentComplete === 100 ? (
                <UploadSuccessIcon />
              ) : (
                <CircleProgressBar progress={percentComplete} baseColor="rgba(117, 117, 117, 1)" progressColor="rgba(5, 195, 221, 1)" />
              )}
            </div>
          </div>
        </div>
        <div className={styles['file-upload-progress']} style={{ width: `${percentComplete}%` }} data-testid="progress-bar"></div>
      </>
    )
  }

  const getResultModalConf = (fileUploadStateType: FileUploadStateType): { content: JSX.Element; type: FileUploadState } => {
    switch (fileUploadStateType) {
      case 'INVALID_FILE_TYPE':
        return {
          content: (
            <FileUploadError
              uploaded="upload error"
              uploadErrorDesc="File type must be webm, mp4, mov and less than 500mb LL360"
              uploadErrorTitle="invalid file type"
            />
          ),
          type: FileUploadState.ERROR
        }

      case 'INVALID_FILE_NAME':
        return {
          content: (
            <FileUploadError
              uploaded="upload error"
              uploadErrorDesc="File names must begin with: EXPERIENCEID_DESCRIPTOR"
              uploadErrorTitle="invalid file name"
            />
          ),
          type: FileUploadState.ERROR
        }

      case 'VERSION_NUMBER_NOT_FOUND':
        return {
          content: (
            <FileUploadError
              uploaded="upload error"
              uploadErrorDesc="File names must have version number such as: v001"
              uploadErrorTitle="version number not found"
            />
          ),
          type: FileUploadState.ERROR
        }

      case 'VERSION_NUMBER_ALREADY_EXISTS':
        return {
          content: (
            <FileUploadError
              uploaded="upload error"
              uploadErrorDesc="File name already exists. Please rename and try again"
              uploadErrorTitle="version number already exists"
            />
          ),
          type: FileUploadState.ERROR
        }

      case 'UPLOAD_ERROR':
        return {
          content: <FileUploadError uploaded="upload error" uploadErrorDesc="Please try again later" uploadErrorTitle="unable to upload" />,
          type: FileUploadState.ERROR
        }

      case 'FILE_SIZE_ERROR':
        return {
          content: <FileUploadError uploaded="upload error" uploadErrorDesc="Max. file size: 500 MB" uploadErrorTitle="unable to upload" />,
          type: FileUploadState.ERROR
        }

      case 'FILE_NAME_TOO_LONG':
        return {
          content: (
            <FileUploadError
              uploaded="upload error"
              uploadErrorDesc="File name must be 100 characters or less"
              uploadErrorTitle="invalid file name"
            />
          ),
          type: FileUploadState.ERROR
        }

      case 'UPLOAD':
      default:
        return {
          content: (
            <div className={styles['upload-controls']}>
              <UploadFillIcon styles={styles} />
              <label className={styles['file-input-label']}>
                <label htmlFor="file-input">Click to upload </label>
                <span>or drag and drop</span>
              </label>
              <Kicker
                fontSize="14px"
                fontWeight="600"
                letterSpacing="none"
                textTransform="none"
                text=".webm, .mp4 or .mov video file"
                margin="4px auto 0"
                lineHeight="21px"
                color="#C6C6C6"
              />
              <Kicker
                fontSize="12px"
                fontWeight="350"
                letterSpacing="none"
                textTransform="none"
                text="max: 500mb LL360"
                margin="4px auto 0"
                lineHeight="21px"
                color="#C6C6C6"
              />
            </div>
          ),
          type: FileUploadState.INITIAL
        }
    }
  }

  const resultModalConfiguration = getResultModalConf(fileUploadState)

  return (
    <div
      className={cx(
        styles[`box`],
        styles[percentComplete > 1 && percentComplete < 100 ? 'upload-border' : 'default-border'],
        styles[percentComplete === 100 ? 'done-border' : ''],
        styles[resultModalConfiguration.type == FileUploadState.ERROR ? 'error' : '']
      )}
      ref={uploadContainerRef}
    >
      {isUploading ? <FileUploadProgress /> : resultModalConfiguration.content}
      <input ref={fileUploadControlRef} className={styles['file-input']} id="file-input" name="file-input" type="file" onChange={handleFileUpload} />
    </div>
  )
}

export default UploadSelectedFile
