import { HeroProps } from '~types'
import styles from './Hero.module.scss'
import { Col, Container, Row } from 'react-grid-system'
import { Heading } from '~elements'

const Hero = ({ posterImage, title, subheadline }: HeroProps) => {
  return (
    <>
      <div className={styles['hero-container']}>
        <div className={styles['hero']}>
          <Container className={'container container--width-100-md-down'} style={{ maxWidth: '1600px', minWidth: '280px', width: '100%' }}>
            <Row>
              <Col>
                {title && <Heading level={1}>{title}</Heading>}
                {subheadline && <p className={styles['subheadline']}>{subheadline}</p>}
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className={styles['hero-container--bg-img']}
          {...(posterImage?.url ? { style: { backgroundImage: `url('${posterImage.url}')` } } : {})}
        />
      </div>
    </>
  )
}

export default Hero
