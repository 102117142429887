// Breakpoints
export const BREAKPOINT_SM_MAX = 767

//Vimeo autoplay url
export const AUTO_PLAY_URL = '?autoplay=true'

// Slugs
export const SLUG_HOMEPAGE = '/home'
export const HOME_URL = '/'
export const SLUG_GALLERY = '/gallery'
export const PREVIEW_PATH = '/preview'
export const PREVIEW_LOGIN_PATH = '/preview/login'

// Fonts
export const TYPEKIT_CSS_URL = 'https://use.typekit.net/bbg3yei.css'

export const SCROLL_ANIMATING = {
  START: 'start',
  STOP: 'stop'
}

//Environments

export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || 'development'
export const isProd = APP_ENV === 'prod'

// Revalidation Interval (in seconds) at (60 * n)
export const REVALIDATION_INTERVAL = 60 * 10

export const SITE_NAME = 'Sphere Partnerships'

export const HEADER_LOGO_URL = 'https://www.thespherevegas.com/'

export enum FORGOT_PASSWORD_ACTIONS {
  INIT = 'init',
  COGNITO_FAILURE = 'cognito_failure',
  CONFIRM = 'confirm',
  RESEND_CODE = 'resend-verification-code'
}

export enum LOGIN_ACTIONS {
  SUBMIT = 'submit',
  SET_NEW = 'set-new',
  SET_NEW_CONFIRM = 'set-new-confirm'
}

export enum VALIDATION_ACTIONS {
  INVALID_INPUT = 'invalid_input'
}

// Gallery Thumbnails Per Page Default Value
export const THUMBNAILS_PER_PAGE = 12

//Local Storage
export enum LOCAL_STORAGE {
  EXO_REMEMBER_ME = 'exo-remember-me',
  USER_KEY = 'x-amz-user-token',
  EXO_PREVIEW = 'exo-preview-url',
  EXO_PREVIEW_FILE_NAME = 'exo-preview-file-name'
}

export enum FEATURE_FLAGGED_STATES {
  ALLOW = 'allow',
  REDIRECT = 'redirect',
  NOT_FEATURE_FLAGGED = 'not_feature_flagged'
}

/**
 * Preview page widths
 */
export const PREVIEW_PAGE_BREAKPOINT = 1024
export const PREVIEW_PAGE_MAX_WIDTH = 1264
export const PREVIEW_PAGE_TITLE_COL_MAX_WIDTH = 500

//File Uploads
export const MAX_FILE_SIZE_IN_KB = 500 * 1024

export enum PREVIEW_DISCONNECT_TYPE {
  BEFORE_UNLOAD_EVENT = 'disconnect/before_unload_event',
  IDLE_TIMEOUT = 'disconnect/idle_timeout',
  USER_PRESSED_EXIT_PREVIEW = 'disconnect/user_pressed_exit_preview'
}
