import { useEffect } from 'react'
import GetAllTimeOfDayCommand from 'render-streaming-client/build/command/GetAllTimeOfDayCommand'
import GetCurrentTimeOfDayCommand from 'render-streaming-client/build/command/GetCurrentTimeOfDayCommand'
import SetTimeOfDayCommand from 'render-streaming-client/build/command/SetTimeOfDayCommand'
import { DropdownSelector, SunnyIcon } from '~elements'
import { ITimeOfDay, usePreviewStore } from 'store'

interface ITimeOfDayData {
  CommandName: string
  Payload: {
    allLightingScenarios: Array<ITimeOfDay>
  }
  Status: boolean
}

interface ICurrentTimeOfDayData {
  CommandName: string
  Payload: {
    lightingScenarioInfo: ITimeOfDay
  }
  Status: boolean
}
const REMOVE_THIS_TIME_OF_DAY = '8A5C9952459A99EB871863B7E9674864'

export const TimeOfDay = () => {
  const { renderingManager, timesOfDay, setTimesOfDay, selectedTimeOfDay, setSelectedTimeOfDay } = usePreviewStore()

  useEffect(() => {
    //Because we are using window.location.href to navigate to/from the video player, there is a hard refresh which resets the store
    //When we start using router.replace, we will only fetch the vantage points when necessary
    if (renderingManager && !timesOfDay.length) {
      getAllTimesOfDay()
    }

    return () => {
      setTimesOfDay([])
      setSelectedTimeOfDay(null)
    }
  }, [renderingManager])

  // Please read comment in useEffect above 👆
  const getAllTimesOfDay = () => {
    if (!renderingManager) {
      return
    }

    const getAllTimeOfDayCommand = new GetAllTimeOfDayCommand()
    renderingManager.onCommandResponse(getAllTimeOfDayCommand, data => {
      try {
        const parsedResponse: ITimeOfDayData = JSON.parse(data)

        const filteredData = parsedResponse.Payload.allLightingScenarios.filter(
          lightingScenario => lightingScenario.scenarioId !== REMOVE_THIS_TIME_OF_DAY
        )
        // setTimesOfDay(parsedResponse.Payload.allLightingScenarios)
        setTimesOfDay(filteredData)
      } catch (err) {
        if (err instanceof Error) {
          throw new Error(err.message)
        }

        throw new Error('Unknown error in fetching times of day.')
      }
    })

    const getCurrent = new GetCurrentTimeOfDayCommand()
    renderingManager.onCommandResponse(getCurrent, data => {
      try {
        const parsedResponse: ICurrentTimeOfDayData = JSON.parse(data)
        setSelectedTimeOfDay(parsedResponse.Payload.lightingScenarioInfo)
      } catch (err) {
        if (err instanceof Error) {
          throw new Error(err.message)
        }

        throw new Error('Unknown error in fetching current time of day.')
      }
    })

    renderingManager.executeCommand(getCurrent)
    renderingManager.executeCommand(getAllTimeOfDayCommand)
  }

  const applyTimeOfDay = (uuid: string) => {
    if (!renderingManager) {
      return
    }

    const setTimeOfDay = new SetTimeOfDayCommand(uuid)

    // Please use for debugging purposes.
    // renderingManager.onCommandResponse(setTimeOfDay, data => {
    //   console.log(data)
    // })

    renderingManager.executeCommand(setTimeOfDay)
  }

  return timesOfDay && selectedTimeOfDay ? (
    <DropdownSelector
      onChange={applyTimeOfDay}
      options={timesOfDay.map(option => {
        return { displayName: option.displayName, scenarioId: option.scenarioId }
      })}
      current={{
        displayName: selectedTimeOfDay.displayName,
        scenarioId: selectedTimeOfDay.scenarioId
      }}
      Icon={SunnyIcon}
      showDivider={false}
    />
  ) : null
}
