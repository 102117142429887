import type { AppProps } from 'next/app'
import { ScreenClassProvider, setConfiguration } from 'react-grid-system'
import Head from 'next/head'
import { ParallaxProvider } from 'react-scroll-parallax'

import { Carousel, Footer, Header, HeroFullScreen, Page } from '~modules'
import { Button, CarouselSlide, Divider, Heading, Paragraph } from '~elements'
import '../styles/variables.scss'
import breakpointVars from '../styles/variables.breakpoints.module.scss'
import '../styles/globals.scss'
import { TYPEKIT_CSS_URL } from '~constants'
import { useEffect } from 'react'
import { useGa } from 'utils/hooks/useGa'

const components = {
  Button: Button,
  Carousel: Carousel,
  CarouselSlide: CarouselSlide,
  Divider: Divider,
  Footer: Footer,
  Header: Header,
  Heading: Heading,
  HeroFullScreen: HeroFullScreen,
  page: Page,
  Paragraph: Paragraph
}

// Configure react-grid-system
// @TODO: define breakpointVars[key] as type number
const breakpoints = Object.keys(breakpointVars).map(key => parseInt(breakpointVars[key]))

const containerWidths = Object.keys(breakpointVars).map(key => (key == 'xs' || key == 'sm' ? '100%' : parseInt(breakpointVars[key] - 48)))

setConfiguration({
  breakpoints: breakpoints,
  containerWidths: containerWidths,
  gutterWidth: 32,
  maxScreenClass: 'xl'
})

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { isReady: isGaReady, sendEvent } = useGa()

  useEffect(() => {
    if (isGaReady) {
      sendEvent('pageview')
    }
  }, [isGaReady, sendEvent])

  return (
    <ParallaxProvider>
      <ScreenClassProvider>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1" />
          <title>Sphere</title>
        </Head>
        <link rel="stylesheet" href={TYPEKIT_CSS_URL} type="text/css" />
        <div id="root-modal" />
        <Component {...pageProps} />
        <div style={{ display: 'none' }}>build number {process.env.NEXT_PUBLIC_BUILD_NUMBER}</div>
      </ScreenClassProvider>
    </ParallaxProvider>
  )
}

export default MyApp
