import RenderStreamingManager from 'render-streaming-client/build/RenderStreamingManager'
import { create } from 'zustand'

interface IPreviewVideo {
  url: string
  fileName: string
}

export interface IVantagePoint {
  scenarioId: string
  displayName: string
  doesExist: boolean
}

export interface ITimeOfDay {
  displayName: string
  scenarioId: string
}

interface IPreviewState {
  renderingManager: null | RenderStreamingManager
  selectedVideo: IPreviewVideo
  vantagePoints: Array<IVantagePoint>
  selectedVantagePoint: IVantagePoint | null
  timesOfDay: Array<ITimeOfDay>
  selectedTimeOfDay: ITimeOfDay | null
  displayExitModal: boolean
  resetRenderingManager: () => void
  setRenderingManager: (manager: RenderStreamingManager) => void
  setSelectedVideo: (video: IPreviewVideo) => void
  setVantagePoints: (allVantagePoints: Array<IVantagePoint>) => void
  setSelectedVantagePoint: (vantagePoint: IVantagePoint | null) => void
  setTimesOfDay: (allTimesOfDay: Array<ITimeOfDay>) => void
  setSelectedTimeOfDay: (timeOfDay: ITimeOfDay | null) => void
  setDisplayExitModal: (displayExitModal: boolean) => void
}

const VIDEO_INITIAL_STATE = {
  url: '',
  fileName: ''
}

export const usePreviewStore = create<IPreviewState>(set => ({
  renderingManager: null,
  selectedVideo: VIDEO_INITIAL_STATE,
  vantagePoints: [],
  selectedVantagePoint: null,
  timesOfDay: [],
  selectedTimeOfDay: null,
  displayExitModal: false,
  resetRenderingManager: () => set({ renderingManager: null }),
  setRenderingManager: manager => set({ renderingManager: manager }),
  setSelectedVideo: video => set({ selectedVideo: video }),
  setVantagePoints: allVantagePoints => set({ vantagePoints: [...allVantagePoints] }),
  setSelectedVantagePoint: vantagePoint => set({ selectedVantagePoint: vantagePoint }),
  setTimesOfDay: allTimesOfDay => set({ timesOfDay: [...allTimesOfDay] }),
  setSelectedTimeOfDay: timeOfDay => set({ selectedTimeOfDay: timeOfDay }),
  setDisplayExitModal: displayExitModal => set({ displayExitModal })
}))
