import { useEffect, useState } from 'react'
import { GalleryProps } from '~types'
import styles from './Gallery.module.scss'
import { useRouter } from 'next/router'
import { THUMBNAILS_PER_PAGE } from '~constants'

import { Gallery as GalleryComponent, IVimeoGalleryVideo, IRouterOptions } from '@msgtechnology/sphere'
import Image from 'next/image'

const Gallery = ({ galleryPlaylist }: GalleryProps) => {
  const router = useRouter()

  const INITIAL_STATE: IRouterOptions = {
    isReady: router.isReady,
    onPushNextRouter: router.push,
    onReplaceNextRouter: router.replace,
    page: router.query.page,
    slug: router.query.slug,
    selectedVideoId: router.query.id
  }
  const [nextJSOptions, setNextJSOptions] = useState<IRouterOptions>(INITIAL_STATE)

  interface IDataState {
    data: IVimeoGalleryVideo[]
    isLoading: boolean
  }
  const [state, setState] = useState<IDataState>({
    data: galleryPlaylist,
    isLoading: true
  })

  const createOptimizedImages = (data: any[]): any[] => {
    return data.map((video: IVimeoGalleryVideo) => {
      if (video.video.posterImage) {
        video.nextImage = <Image key={video.sId} src={video.video.posterImage} width="0" height="0" sizes="100vw" alt="Video thumbnail" />
      }
      return video
    })
  }

  useEffect(() => {
    if (!router.isReady) return

    setNextJSOptions((prevState: IRouterOptions) => {
      return { ...prevState, isReady: router.isReady, page: router.query.page, selectedVideoId: router.query.id }
    })
  }, [router.isReady, router.query.page, router.query.id])

  useEffect(() => {
    if (state.isLoading && galleryPlaylist.length) {
      const playlistWithOptimizedImages = createOptimizedImages(galleryPlaylist)
      setState({ data: [...playlistWithOptimizedImages], isLoading: false })
    }
  }, [state.isLoading, galleryPlaylist])

  return (
    <>
      {state.isLoading ? (
        <div className={styles['loading__overlay']}>
          <p>Loading...</p>
        </div>
      ) : null}
      <div className={styles['gallery-container']} id={'gallery-container'}>
        {state.data ? (
          <GalleryComponent
            data={state.data}
            numThumbnailsPerPage={THUMBNAILS_PER_PAGE}
            nextJSOptions={nextJSOptions}
            resultsHeading={'Exosphere Gallery'}
            isLoading={state.isLoading}
            useUniqueLinksForModals={true}
          />
        ) : null}
      </div>
    </>
  )
}

export default Gallery
