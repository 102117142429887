import React from 'react'
import { useRouter } from 'next/router'
import { type FileRecord } from '../ListView/ListView'
import { PREVIEW_PATH } from '~constants'
import styles from './index.module.scss'
import { LanguageFillIcon } from '../Icons'
import { usePreviewStore } from 'store'
import { createPreviewUrl } from 'services/createPreviewUrl'

interface IPreviewFileProps {
  record: FileRecord
  displayError: React.Dispatch<React.SetStateAction<string | null>>
}

const PreviewSelectedFile = ({ record, displayError }: IPreviewFileProps) => {
  const router = useRouter()
  const { setSelectedVideo } = usePreviewStore()

  const handlePreviewClick = async () => {
    // Get presigned url and then send user to the preview link.
    try {
      const presignedUrl = await createPreviewUrl(record.title)

      if (presignedUrl) {
        setSelectedVideo({ url: presignedUrl, fileName: record.title })
        router.push(`${PREVIEW_PATH}/video?id=${record.id}`)
      }
    } catch (error) {
      console.error(error)
      displayError(`Failed to preview file ${record.title}`)
    }
  }

  return (
    <button className={styles['preview-button']} onClick={handlePreviewClick} data-testid="preview-button">
      <LanguageFillIcon />
      Preview
    </button>
  )
}

export default PreviewSelectedFile
