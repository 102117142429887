import { useEffect } from 'react'
import ApplyVantagePointCommand from 'render-streaming-client/build/command/ApplyVantagePointCommand'
import GetCurrentVantagePoint from 'render-streaming-client/build/command/GetCurrentVantagePointCommand'

import GetAllVantagePointsCommand from 'render-streaming-client/build/command/GetAllVantagePointsCommand'
import { DropdownSelector, VideocamIcon } from '~elements'
import { IVantagePoint, usePreviewStore } from 'store'

interface IVantagePointData {
  CommandName: string
  Payload: {
    allVantagePointsInfo: Array<IVantagePoint>
  }
  Status: boolean
}

interface ICurrentVantagePointData {
  CommandName: string
  Payload: {
    vantagePointInfo: IVantagePoint
  }
  Status: boolean
}

const REMOVE_THESE_VANTAGE_POINTS = [
  'd37c9343-0c38-4bd4-ae30-1fa0118c258b',
  '830b1569-a944-4813-9002-26f577a10fc5',
  'b9d6fb75-8b6b-4ce8-ae7e-a47aebfa1aa3'
]

export const VantagePoints = () => {
  const { renderingManager, setVantagePoints, vantagePoints, setSelectedVantagePoint, selectedVantagePoint } = usePreviewStore()

  useEffect(() => {
    //Because we are using window.location.href to navigate to/from the video player, there is a hard refresh which resets the store
    //When we start using router.replace, we will only fetch the vantage points when necessary
    if (renderingManager && !vantagePoints.length) {
      getVantagePoints()
    }

    return () => {
      setSelectedVantagePoint(null)
      setVantagePoints([])
    }
  }, [renderingManager])

  const getVantagePoints = () => {
    if (!renderingManager) {
      return
    }

    const getAllVantage = new GetAllVantagePointsCommand()
    renderingManager.onCommandResponse(getAllVantage, data => {
      try {
        const parsedResponse: IVantagePointData = JSON.parse(data)
        const filteredData = parsedResponse.Payload.allVantagePointsInfo.filter(
          vantagePoint => REMOVE_THESE_VANTAGE_POINTS.indexOf(vantagePoint.scenarioId) === -1
        )
        // setVantagePoints(parsedResponse.Payload.allVantagePointsInfo)
        setVantagePoints(filteredData)
      } catch (err) {
        if (err instanceof Error) {
          throw new Error(err.message)
        }

        throw new Error('Unknown error in vantage points.')
      }
    })

    const getCurrent = new GetCurrentVantagePoint()
    renderingManager.onCommandResponse(getCurrent, data => {
      try {
        const parsedResponse: ICurrentVantagePointData = JSON.parse(data)
        setSelectedVantagePoint(parsedResponse.Payload.vantagePointInfo)
      } catch (err) {
        if (err instanceof Error) {
          throw new Error(err.message)
        }

        throw new Error('Unknown error in vantage points.')
      }
    })
    renderingManager.executeCommand(getCurrent)
    renderingManager.executeCommand(getAllVantage)
  }

  const applyVantagePoint = (uuid: string) => {
    if (!renderingManager) {
      return
    }

    const applyVantagePoint = new ApplyVantagePointCommand(uuid)

    console.log(`Attempt to apply vantage point ${uuid}`)

    // Please use for debugging purposes.
    // renderingManager.onCommandResponse(applyVantagePoint, data => {
    //   console.log(data)
    // })

    renderingManager.executeCommand(applyVantagePoint)
  }

  return vantagePoints && selectedVantagePoint ? (
    <DropdownSelector onChange={applyVantagePoint} options={vantagePoints} current={selectedVantagePoint} Icon={VideocamIcon} />
  ) : null
}
